<template>
  <div>

    <patient-progress-form v-model="showProgressForm" :progress="editProgress" @after-save="onEditProgressSave"></patient-progress-form>

    <el-row>
      <el-col :span="12">
        <h5>Verlaufsbeobachtungen</h5>
        <el-button type="primary" @click="onBtnNewProgressClick" size="mini" icon="el-icon-circle-plus">Verlaufsbeobachtung hinzufügen</el-button>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button  type="warning" @click="onSaveProgressEvaluations">Verlaufsbeobachtungen speichern</el-button>
      </el-col>
    </el-row>

    <el-row class="mt-1 mb-4">
      <el-col :span="24">

        <el-table
            ref="progresstable"
            :data="patientProgresses"
            :default-sort = "{prop: 'date_start', order: 'ascending'}"
            style="width: 100%"
            highlight-current-row
            @row-click="onProgressClick"
            stripe>
          <el-table-column
              prop="meta.therapy.code"
              label="Therapie">
          </el-table-column>
          <el-table-column
              prop="title"
              label="Name">
          </el-table-column>
          <el-table-column
              prop="date_start"
              label="Beginn"
              sortable>
            <template #default="scope">
              <span v-if="scope.row.date_start">{{ $filters.germanDate(scope.row.date_start) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="date_end"
              label="Ende"
              sortable>
            <template #default="scope">
              <span v-if="scope.row.date_end">{{ $filters.germanDate(scope.row.date_end) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150">
            <template #default="scope">
              <div class="text-right">
                <el-button size="mini" type="warning" @click="onProgressEdit(scope.row)" icon="el-icon-edit"></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

      </el-col>
    </el-row>

    <div v-if="currentProgress">
    <h5>Verlaufsbeurteilungen für {{currentProgress.title}}</h5>
      <el-button type="primary" @click="onBtnNewProgressEvaluationClick" size="mini" icon="el-icon-circle-plus">Verlaufsbeurteilungen hinzufügen</el-button>

      <el-row class="mt-1">
        <el-col :span="16">
          <progress-evaluation-table ref="evaluationstable" :data="currentProgress.evaluations" :clickable="true" :deleteable="true" @on-row-delete="onDeleteProgressEvaluation" @on-row-click="onEvaluationClick"></progress-evaluation-table>
        </el-col>
        <el-col class="pl-4" :span="8" v-if="currentProgressEvaluation">

          <h6>Datum</h6>
          <el-date-picker
              class="w-100"
              v-model="currentProgressEvaluation.date"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              @change="onDateChange"
              placeholder="Datum auswählen">
          </el-date-picker>

          <h6 class="mt-3">Recist / Beurteilung</h6>
          <el-select size="medium" class="w-100" v-model="currentProgressEvaluation.recist_id" filterable>
            <el-option
                v-for="item in recist"
                :key="item.id"
                :label="(item.title + ' (' + item.code + ')')"
                :value="item.id">
            </el-option>
          </el-select>

          <h6 class="mt-3">Tumorstatus</h6>
          <el-select size="medium" class="w-100" v-model="currentProgressEvaluation.tumorstatus" filterable>
            <el-option label="kein Tumor nachweisbar" value="K"></el-option>
            <el-option label="Tumorreste (Residualtumor)" value="T"></el-option>
            <el-option label="Tumorreste (Residualtumor) Progress" value="P"></el-option>
            <el-option label="Tumorreste (Residualtumor) No Change" value="N"></el-option>
            <el-option label="Lokalrezidiv" value="R"></el-option>
            <el-option label="Fraglicher Befund" value="F"></el-option>
            <el-option label="kein Tumor nachweisbar" value="U"></el-option>
            <el-option label="fehlende Angabe" value="X"></el-option>
          </el-select>

          <h6 class="mt-3">Tumorstatus Lymphknoten</h6>
          <el-select size="medium" class="w-100" v-model="currentProgressEvaluation.tumorstatus_lymph_nodes" filterable>
            <el-option label="Kein Lymphknotenbefall nachweisbar" value="K"></el-option>
            <el-option label="Bekannter Lymphknotenbefall Residuen" value="T"></el-option>
            <el-option label="Bekannter Lymphknotenbefall Progress" value="P"></el-option>
            <el-option label="Bekannter Lymphknotenbefall No Change" value="N"></el-option>
            <el-option label="Neu aufgetretenes Lymphknotenrezidiv" value="R"></el-option>
            <el-option label="Fraglicher Befund" value="F"></el-option>
            <el-option label="Kein Tumor nachweisbar" value="U"></el-option>
            <el-option label="Fehlende Angabe" value="X"></el-option>
          </el-select>

          <h6 class="mt-3">Tumorstatus Fernmetastasen</h6>
          <el-select size="medium" class="w-100" v-model="currentProgressEvaluation.tumorstatus_distant_metastases" filterable>
            <el-option label="Fernmetastasenl Residuen" value="T"></el-option>
            <el-option label="Fernmetastasen Progress" value="P"></el-option>
            <el-option label="Fernmetastasen No Change" value="N"></el-option>
            <el-option label="Neu aufgetretene Fernmetastase(n) bzw. Metastasenrezidiv" value="R"></el-option>
            <el-option label="Fraglicher Befund" value="F"></el-option>
            <el-option label="Kein Tumor nachweisbar" value="U"></el-option>
            <el-option label="Fehlende Angabe" value="X"></el-option>
          </el-select>

          <h6 class="mt-3">Befunde</h6>
          <el-input
              type="textarea"
              :rows="2"
              v-model="currentProgressEvaluation.result"></el-input>

          <h6 class="mt-3">Prozedere</h6>
          <el-input
              type="textarea"
              :rows="2"
              v-model="currentProgressEvaluation.therapy_plan"></el-input>

          <h6 class="mt-3">Dosierung / Kommentar</h6>
          <el-input
            type="textarea"
            :rows="2"
            v-model="currentProgressEvaluation.comment"></el-input>

          <h6 class="mt-3">Ende Beurteilung</h6>
          <el-select size="medium" class="w-100" v-model="currentProgressEvaluation.completed_state" filterable>
            <el-option
                v-for="item in patient_progress_evaluation_completed_states"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>


        </el-col>
      </el-row>

      <el-row class="mt-4">
        <el-col :span="12" :offset="12" class="text-right">
          <el-button  type="warning" @click="onSaveProgressEvaluations">Verlaufsbeurteilungen speichern</el-button>
        </el-col>
      </el-row>

    </div>
    <div v-else>
      <span v-if="currentPatient && currentPatient.progress && currentPatient.progress.length > 0">Wählen Sie bitte eine bestehende Verlaufsbeobachtung aus, um an dieser Stelle eine Beurteilung eingeben zu können.</span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PatientProgressForm from "@/components/patient/PatientProgressForm";
import moment from "moment";
import ProgressEvaluationTable from "@/components/parts/ProgressEvaluationTable";

const _ = require('lodash');

export default {
  name: "PatientProgresses",
  props: [],
  components: {
    ProgressEvaluationTable,
    PatientProgressForm
  },
  data() {
    return {
      patientProgresses: [],
      currentProgress: null,
      currentProgressEvaluation: null,
      showProgressForm: false,
      editProgress: {}
    }
  },
  mounted: async function () {
    await this.getRecist()
  },
  computed: {
    ...mapGetters('patients', ['currentPatient']),
    ...mapGetters('recist', ['recist']),
    ...mapGetters('patient_progress_evaluation_completed_states', ['patient_progress_evaluation_completed_states']),
  },
  watch: {
    currentPatient: function (val) {
      this.patientProgresses = _.cloneDeep(val.progress)
    }
  },
  methods: {
    ...mapActions('recist', ['getRecist']),
    ...mapActions('patients', ['reloadCurrentPatient', 'updateProgressEvaluationsOfPatient', 'deleteProgressEvaluationOfPatient']),
    onBtnNewProgressClick() {
      this.editProgress = {
        date_start: moment().format("YYYY-MM-DD"),
        patient_id: this.currentPatient.id
      }
      this.showProgressForm = true
    },
    onBtnNewProgressEvaluationClick() {

      const exist = _.find(this.currentProgress.evaluations, (o) => o.id == 0)
      if(exist) return

      this.currentProgress.evaluations.push({
        id: 0,
        date: moment().format("YYYY-MM-DD") + " 12:00:00",
        tumorstatus: 'X',
        tumorstatus_lymph_nodes: 'X',
        tumorstatus_distant_metastases: 'X',
        meta: {
          recist: {}
        }
      })
      this.currentProgressEvaluation = this.currentProgress.evaluations[this.currentProgress.evaluations.length-1]
      this.$refs.evaluationstable.setCurrentRow(this.currentProgressEvaluation)

    },
    onProgressClick(row) {
      this.currentProgress = row
      this.currentProgressEvaluation = null
    },
    onProgressEdit(row) {
      this.editProgress = row
      this.showProgressForm = true
    },
    onEvaluationClick(row) {
      this.currentProgressEvaluation = row
    },
    async onEditProgressSave() {
      await this.reloadCurrentPatient()
      this.patientProgresses = _.cloneDeep(this.currentPatient.progress)
      this.currentProgress = null
    },
    async onSaveProgressEvaluations() {
      await this.updateProgressEvaluationsOfPatient(this.patientProgresses)
      await this.onEditProgressSave()
      this.currentProgressEvaluation = null
    },
    async onDeleteProgressEvaluation(evaluation) {
      if(evaluation.id > 0) {
        await this.deleteProgressEvaluationOfPatient({
          patient_id: this.currentProgress.patient_id,
          evaluation_id: evaluation.id
        })
      }
      _.remove(this.currentProgress.evaluations, (o) => o.id == evaluation.id)
    },
    onDateChange(val) {
      this.currentProgressEvaluation.date = moment(val).format("YYYY-MM-DD")
    }
  }
}
</script>
<style scoped>
</style>
